.btn {
  display: inline-block;
  font-family: $btn-font-family;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: $primary;
  border: 1px solid transparent;
  font-size: rem(18px);
  font-weight: 600;
  border-radius: rem(8px);
  max-width: 100%;
  text-align: center;
  padding: rem(16px) rem(32px);
  svg {
    margin: 0.3rem;
  }

  @include transition($btn-transition);
  &:focus {
    outline: 0;
  }
  &-primary {
    background: $primary;
    color: $white;
    &:hover {
      background: transparent;
      border-color: $primary;
      color: $primary !important;
    }
    &-outline {
      background: transparent;
      border-color: $primary;
      color: $primary;
      &:hover {
        background: $primary;
        color: $white !important;
      }
    }
  }
  &-secondary {
    background: transparent;
    border-color: $secondary;
    color: $primary;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      border-color: $secondary;
      color: $secondary;
    }
  }

  &-outline {
    border: 2px solid $secondary;
    height: auto !important;
    width: auto !important;
    padding: 1rem;
    background-color: transparent;
    color: $secondary;
    line-height: 1rem;
    margin: 0.2rem;
    &:hover {
      background: $secondary;
      color: $white;
    }
  }
}
