@import "./fonts";
@import "./bootstrap";
@import "./utils";
@import "./libs";
@import "../../node_modules/sal.js/dist/sal.css";
@import "./components/TheHeader.scss";
@import "./components/TheFooter.scss";
@import "./components/Btn.scss";
@import "./components/Input.scss";
@import "./components/menuOverlay.scss";
@import "./components/AppImage.scss";
@import "./components/Socials.scss";

@import "../../node_modules/glightbox/dist/css/glightbox.css";
// Pages
@import "./pages/index.scss";
// libs
@import "swiper/swiper-bundle.css";
@import "./overide.scss";
html {
  scroll-behavior: smooth;
  ::-moz-selection {
    background: $secondary;
  }

  ::selection {
    background: $secondary;
  }
  font-size: 16px;

  overflow-x: hidden;
}
.theApp {
  &-body {
    min-height: 50vh;
  }
  &.dark {
    .header {
      background-color: $dark !important;
    }
  }
}
