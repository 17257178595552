.header {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0rem;
  left: 0rem;
  color: $white;
  padding: 1.3rem 0rem;
  z-index: 9999;
  padding-top: 4rem;
  @include media-breakpoint-down(md) {
    padding-top: 2rem;
  }
  &-bars {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $primary;
    border: 0.1rem solid $primary;
    color: $white;
    outline: none !important;
    transition: all 0.1s ease-in-out;
    &:active,
    &:focus,
    &:hover {
      color: $primary;
      background-color: transparent;
    }
  }
  &-logo {
    @include media-breakpoint-down(lg) {
      img {
        width: 5rem;
        height: auto;
      }
    }
  }
  &-menu {
    ul {
      list-style: none;
      li {
        a {
          padding: 0.5rem 1rem;
          border-radius: rem(0.4px);
          font-size: rem(18px);
          &:hover {
            display: inline-block;
            color: $primary !important;
          }
        }
      }
    }
  }
}

.headroom {
  will-change: transform;
  transition: all 200ms linear;
  &--not {
    &-top {
      background: rgba(255, 255, 255, 1);
      backdrop-filter: blur(10px);
      padding: 1rem 0rem;
      box-shadow: 0rem 0rem 1rem 0.5rem rgba(0, 0, 0, 0.1);
      .header {
        &-menu {
          ul {
            li {
              a {
                color: $body-color;
              }
            }
          }
        }
      }
    }
  }
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
