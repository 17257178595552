.intro {
  padding-top: 10rem;
  background: #0f74b2 url("../static/images/header-bg.png") no-repeat center
    center;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    padding-top: 8rem;
  }
}

.flyersApp {
  background-color: $gray-500;
}

.contactUs {
  &-content {
    background-color: $gray-500;
    padding: 3rem 2rem;
    border-radius: rem(20px);
  }
}

.mainPageHeader {
  background: #0f74b2 url("../static/images/main-header-image.png") no-repeat
    center center;
  background-size: cover;
  margin-top: 9rem;
  padding: 3rem 0rem;
  @include media-breakpoint-down(lg) {
    margin-top: 6.5rem;
  }
}

.about {
  &-image {
    border-radius: 1rem;
    overflow: hidden;
  }
}

.faq {
  &-dropdown {
    border: 0.1rem solid $gray-500;
    background-color: $gray-300;
    border-radius: 0.5rem;
    &-header {
      position: relative;
      z-index: 2;
      cursor: pointer;
      user-select: none;
      padding: 1.2rem 1rem;
    }
    &-body {
      position: relative;
      z-index: 1;
      border-top: 0.1rem solid $gray-400;
      height: 0rem;
      opacity: 0;
      transition: all 0.1s ease-in-out;
    }
    &-icon {
      transition: all 0.1s ease-in-out;
      .svgIcon {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
    &.is-active {
      .faq {
        &-dropdown {
          &-header {
            color: $primary;
          }

          &-body {
            padding: 1.2rem 1rem;
            height: auto;
            opacity: 1;
            border-top-color: $primary;
          }

          &-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}

.subPages {
  .header {
    padding: 1.4rem 0rem !important;
    &-menu {
      ul {
        li {
          a {
            color: $body-color;
          }
        }
      }
    }
  }
}
