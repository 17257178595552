.socials {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -0.3em;
  list-style-type: none;
  align-items: center;
  padding: 0;
  @include font-size(20px);
  > li {
    display: inline-block;
  }

  a {
    color: $white;
    border-radius: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 200ms ease-in-out;
    width: rem(40px);
    height: rem(40px);
    border-radius: 20rem;
    border: 0.1rem solid $white;
    color: $white;
    margin-right: 0.3rem;
    margin-bottom: 0.4rem;
    svg {
      width: 1rem;
      height: 1rem;
    }

    &.is-facebook {
      background-color: $facebook;
      &:hover {
        color: $facebook !important;
        background-color: transparent;
        border-color: $facebook;
      }
    }

    &.is-twitter {
      background-color: $twitter;
      &:hover {
        color: $twitter !important;
        background-color: transparent;
        border-color: $twitter;
      }
    }

    &.is-youtube {
      background-color: $youtube;
      &:hover {
        color: $youtube !important;
        background-color: transparent;
        border-color: $youtube;
      }
    }

    &.is-instagram {
      background-color: $instagram;
      &:hover {
        color: $instagram !important;
        background-color: transparent;
        border-color: $instagram;
      }
    }
  }
  &.is-dark {
    a {
      background: #ededed;
    }
  }
}
