.footer {
  background-color: $gray-500;
  &-rights{
    border-top: .1rem solid $secondary;
  }
  &-subscription{
    position: relative;
    width: rem(400px);
    max-width: 100%;
    input[type="text"] {
      height: rem(45px) !important;
      border-radius: 0.2rem !important;
      border: .1rem solid $gray-600 !important;
    }
    &-button{
      position: absolute;
      top:0rem;
      inset-inline-end: 0rem;
      z-index: 9;
      height: 100% !important;
      padding: 0rem 1rem !important;
      border-top-left-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
    }
  }
}
