img {
  user-select: none;
}

hr {
  border-color: $body-color;
  border-top-width: 3px;
  opacity: 0.15;
}

.products-row {
  @include media-breakpoint-down(xl) {
    margin-left: rem(-7.5px);
    margin-right: rem(-7.5px);
    > * {
      padding-left: rem(7.5px);
      padding-right: rem(7.5px);
    }
    .mb-col {
      margin-bottom: rem(15px);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: rem(-4px);
    margin-right: rem(-4px);
    > * {
      padding-left: rem(4px);
      padding-right: rem(4px);
    }
    .mb-col {
      margin-bottom: rem(8px);
    }
  }
}


// IMAGES
.iMax {
  display: block;
  max-width: 100%;
}
.ifluid {
  width: 100%;
  height: auto;
}

.section {
  position: relative;
  width: 100%;
  height: auto;
}

a {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $secondary !important;
  }
}


.ml-auto {
  margin-left: auto !important;
  @include media-breakpoint-down(md) {
    margin-left: 0rem !important;
  }
}

.ObContain {
  object-fit: contain !important;
}

@include media-breakpoint-up(lg) {
  .overflow-auto {
    overflow: hidden !important;
  }
}

.form-control {
  padding-right: 2rem;
  height: rem(50px);
}

input {
  border-radius: rem(8px) !important;
  height: rem(62px) !important;

}

.form-select {
  background-position: left 0.75rem center !important;
  height: rem(50px);
}

textarea {
  min-height: rem(120px);
  border-radius: rem(8px) !important;
}


.swiper {
  &-pagination {
    position: relative;
    &-bullet {
      border-radius: 0.1rem;
      width: 1rem;
      height: 0.15rem;
      background-color: rgba(0, 0, 0, 0.4);
      transition: all 0.2s ease-in-out;
      &-active {
        width: 3rem;
        background-color: $primary;
      }
    }
  }
}

.text {
  &-gray {
    &-300 {
      color: $gray-300;
    }
    &-400 {
      color: $gray-400;
    }
    &-500 {
      color: $gray-500;
    }
  }
}
