// Spacing
$sizeUnit: rem;
$marginKey: "m";
$paddingKey: "p";
$separator: "-";
$sizes: (
  ("none", 0),
  ("xxs", 10px),
  ("xs", 14px),
  ("sm", 20px),
  ("md", 30px),
  ("lg", 40px),
  ("xl", 80px),
  ("xxl", 120px)
);
$positions: (("t", "top"), ("r", "right"), ("b", "bottom"), ("l", "left"));

@function sizeValue($key, $value) {
  @return if($key== "none", 0, $value + $sizeUnit);
}

@each $size in $sizes {
  $sizeKey: nth($size, 1);
  $sizeValue: nth($size, 2);

  .#{$marginKey}#{$separator}#{$sizeKey} {
    @include margin($sizeValue);
  }

  .#{$paddingKey}#{$separator}#{$sizeKey} {
    @include padding($sizeValue);
  }

  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);

    .#{$marginKey}#{$posKey}#{$separator}#{$sizeKey} {
      @include rfs($sizeValue, margin-#{$posValue});
    }

    .#{$paddingKey}#{$posKey}#{$separator}#{$sizeKey} {
      @include rfs($sizeValue, padding-#{$posValue});
    }
  }
}

.mb-col {
  margin-bottom: $grid-gutter-width;
}
.mt-col {
  margin-top: $grid-gutter-width;
}

.rounded-pill {
  border-radius: 20rem !important;
}
.order {
  &-lg {
    @include media-breakpoint-down(lg) {
      order: -1;
    }
  }

  &-md {
    @include media-breakpoint-down(md) {
      order: -1;
    }
  }

  &-sm {
    @include media-breakpoint-down(sm) {
      order: -1;
    }
  }
}
.fs {
  &-xxxl {
    @include font-size(50px);
    font-family: $font-family-heading;
    @include media-breakpoint-down(lg) {
      @include font-size(45px);
    }
    @include media-breakpoint-down(sm) {
      @include font-size(40px);
    }
  }
  &-xxl {
    @include font-size(45px);
    font-family: $font-family-heading;
    @include media-breakpoint-down(lg) {
      @include font-size(35px);
    }
    @include media-breakpoint-down(sm) {
      @include font-size(30px);
    }
  }
  &-xl {
    @include font-size(30px);
    font-family: $font-family-heading;
  }
  &-lg {
    @include font-size(22px);
    font-family: $font-family-heading;
    @include media-breakpoint-down(lg) {
      font-size: rem(20px);
    }
  }
  &-md {
    @include font-size(18px);
    @include media-breakpoint-down(lg) {
      @include font-size(18px);
    }
  }
  &-sm {
    @include font-size(16px);
    @include media-breakpoint-down(lg) {
      @include font-size(15px);
    }
  }
  &-xs {
    @include font-size(14px);
  }
  &-xxs {
    @include font-size(10px);
  }
}

.fw {
  &-lx {
    font-weight: 200;
  }
  &-light {
    font-weight: 300;
  }
  &-normal {
    font-weight: 400;
  }
  &-medium {
    font-weight: 500;
  }
  &-semiBold {
    font-weight: 600;
  }
  &-bold {
    font-weight: 700;
  }
}

.max {
  &-lg {
    max-width: 1000px;
  }
  &-md {
    max-width: 800px;
  }
  &-sm {
    max-width: 520px;
  }
  &-xs {
    max-width: 260px;
  }
  &-xxs {
    max-width: 140px;
  }
}

.svgIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  &.is-lg {
    @include font-size(32px);
  }
  &.is-xl {
    font-size: 2em;
  }
  &.is-xxl {
    font-size: rem(100px);
  }
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > * {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 0.6em;
    }
    &:before {
      content: "";
      width: 0.5em;
      height: 0.5em;

      background: $body-color;
      border-radius: 20rem;
      display: flex;
      margin: 0.5em 0 0 0.5em;
      flex: 0 0 0.5em;
    }
  }
  &.is-2 {
    @include media-breakpoint-up(sm) {
      margin-left: -4px;
      margin-right: -4px;
      > * {
        display: inline-flex;
        width: 50%;
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}

.pt-header {
  @include padding-top(160px);
}

.bg-gray {
  background: #f3f3f3;
}
.bg-light {
  background: rgba($primary, 0.1);
}

.line {
  background: rgba($body-color, 0.1);
  height: 100%;
  width: 1px;
  margin: 0 auto;
}

.list-style {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li::before {
    content: "\2022";
    color: $primary;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  li {
    padding: rem(8px);
    font-size: rem(18px);
  }
  &.is-2 {
    display: flex;
    flex-wrap: wrap;
    > li {
      width: 50%;
    }
  }
}

.overlay {
  position: relative;
  &-img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 22;
    background: $dark;
    opacity: 0.75;
  }
  &-serviceImg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 22;
    background: $dark;
    opacity: 0.75;
    border-radius: rem(16px);
  }
  img {
    object-fit: cover;
  }
}

.overview {
  position: sticky;
  z-index: 3333;
  top: 9rem;
  padding: rem(25px);
  border-radius: 25px (14px);
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      &:first-child {
        color: $primary;
        &::before {
          width: 2px;
          height: 20px;
          background: $primary;
          display: inline-block;
          content: "";
          margin-right: 10px;
        }
      }
      color: $dark;
      font-weight: bold;
      font-size: rem(18px);
      padding: rem(5px);
      &.is-bullet {
        font-weight: 500;
        font-size: rem(16px);
        &::before {
          content: "\2022";
          color: $info;
          font-weight: bold;
          display: inline-block;
          width: 1em;
        }
      }
    }
  }
}

.overlay-swiper {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to left top, #000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: rem(14px);
  @include media-breakpoint-down(md) {
    border-radius: 0.4rem;
  }
}

.quality-head {
  line-height: 1.5;
  width: 90%;
}

.lh {
  &-sm {
    line-height: rem(40px) !important;
  }
  &-md {
    line-height: rem(48px);
  }
}
